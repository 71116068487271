import {
  isBrowser,
  readFromLocalStorage,
  writeToLocalStorage,
  removeFromLocalStorage,
} from "../util"

const userReducer = (
  state = {
    session: readFromLocalStorage("session") || null,
    username: readFromLocalStorage("username") || null,
    password: readFromLocalStorage("password") || null,
  },

  action
) => {
  switch (action.type) {
    case "LOGGED_IN":
      let { data, username, password } = action.payload.data

      writeToLocalStorage("session", data.session)
      writeToLocalStorage("username", username)
      writeToLocalStorage("password", password)

      state = {
        session: data.session,
        username: username,
        password: password,
      }
      break

    case "LOGGED_OUT":
      removeFromLocalStorage("session")
      removeFromLocalStorage("username")
      removeFromLocalStorage("password")
      state = {
        session: null,
        username: null,
        password: null,
      }
      break

    case "SIGNING_IN":
      let { name } = action.payload
      writeToLocalStorage("username", name.username)
      writeToLocalStorage("password", name.password)
      state = {
        username: name.username,
        password: name.password,
      }
      break

    default:
      break
  }

  return state
}

export default userReducer
