module.exports = [{
      plugin: require('/opt/render/project/src/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"ETCool - Attendance Analyser for NSSCE","short_name":"ETCool","start_url":"/","background_color":"#861a54","theme_color":"#861a54","display":"standalone","icon":"src/images/etcool.png"},
    },{
      plugin: require('/opt/render/project/src/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[],"appendScript":"/opt/render/project/src/src/service-worker.js"},
    },{
      plugin: require('/opt/render/project/src/node_modules/gatsby-plugin-react-redux/gatsby-browser.js'),
      options: {"plugins":[],"pathToCreateStoreModule":"./src/store","serialize":{"space":0,"isJSON":true,"unsafe":false}},
    },{
      plugin: require('/opt/render/project/src/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-159928138-1","head":true,"anonymize":true,"respectDNT":true,"exclude":["/preview/**","/do-not-track/me/too/"],"pageTransitionDelay":0,"sampleRate":5,"siteSpeedSampleRate":10,"cookieDomain":"etcool.onrender.com"},
    },{
      plugin: require('/opt/render/project/src/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
