import { combineReducers, createStore } from "redux"
import userReducer from "./reducer/userReducer"
import loadingReducer from "./reducer/loadingReducer"

const rootReducer = combineReducers({
  user: userReducer,
  loading: loadingReducer,
})

export default preloadedState => {
  return createStore(rootReducer, preloadedState)
}
