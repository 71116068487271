import axios from "axios"

export const isBrowser = () => typeof window !== "undefined"

export const writeToLocalStorage = (key, value) => {
  if (isBrowser()) {
    localStorage.setItem(key, value)
  } else {
    console.log("Unable to access localstorage")
  }
}

export const readFromLocalStorage = key => {
  if (isBrowser()) {
    return localStorage.getItem(key)
  } else {
    console.log("Unable to access localstorage")
  }
}

export const removeFromLocalStorage = key => {
  if (isBrowser()) {
    localStorage.removeItem(key)
  } else {
    console.log("Unable to access localstorage")
  }
}

export const login = async ({
  username,
  password,
  setUser,
  setLoading,
  stopLoading,
  openSnackbar,
}) => {
  return await axios
    .post("https://etcool-api.herokuapp.com/login", {
      username,
      password,
    })
    .then(res => res.data)
    .then(result => {
      if (result.success === true) {
        setLoading("Login success full. Please wait...")

        setUser({
          data: result.data,
          username: username,
          password: password,
        })
      } else {
        stopLoading()
        openSnackbar({
          snackbarText: result.message,
          snackbarType: "error",
        })
      }
    })
    .catch(error => console.log(error))
}
