const loadingReducer = (
  state = {
    loading: false,
    loadingText: "",
    snackbarOpen: false,
    snackbarType: "",
    snackbarText: "",
  },
  action
) => {
  switch (action.type) {
    case "SET_LOADING":
      let { loadingText } = action.payload

      state = {
        loading: true,
        loadingText: loadingText,
      }
      break

    case "STOP_LOADING":
      state = {
        loading: false,
        loadingText: "",
      }
      break

    case "OPEN_SNACKBAR":
      let { snackbarType, snackbarText } = action.payload
      state = {
        snackbarOpen: true,
        snackbarType: snackbarType,
        snackbarText: snackbarText,
      }
      break

    case "CLOSE_SNACKBAR":
      state = {
        snackbarOpen: false,
      }
      break

    default:
      break
  }

  return state
}

export default loadingReducer
